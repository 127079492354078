export default {
    methods: {
        async categoriesListBuilder(parent_id) {
            try {
                const roots = await this.getRootsCategories(parent_id)
                const descendants = await this.getDescendantsForRoots(roots)
                const rawList = await this.buildRawList(descendants)
                const formatedList = await this.buildFormatedList(rawList)
                
                return formatedList
            } catch(e) {
                console.log(e)
            }
            
        },
        async getRootsCategories(parent_id) {
            const roots = await this.axios({
                method: 'GET',
                url: 'cat/children/' + parent_id
            })
            
            return roots
        },
        async getDescendantsForRoots(roots) {
            //axios multirequest setup
    
            let requests = []
    
            roots.data.data.forEach(item => {
                requests.push(this.axios({
                    method: 'GET',
                    url: 'cat/descendants',
                    params: {
                        id: item._id
                    }
                }))
            })
    
            let rootsList = [...roots.data.data]

            const results = await this.axios.all(requests)
            const responses = [...results]
                
            return {rootsList: rootsList, responses: responses}
        },
        buildRawList(data) {
            let rawList = []
            data.rootsList.forEach((root, i) => {
                rawList.push(root)
                
                data.responses[i].data.data.descendants.forEach(child => {
                    rawList.push(child)
                })
            })
                        
            return rawList
        },
        buildFormatedList(raw_list) {
            let formatedList = []
            formatedList.push({
                name: 'Все разделы',
                value: 0
            })
            raw_list.forEach(item => {
                formatedList.push({
                    name: item.name,
                    value: item._id
                })
            })

            return formatedList
        }
    }
}